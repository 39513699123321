<template>
  <div class="c-section__top">
    <div class="c-video-info bg-light p-4 p-xxl-5">
      <div class="row gx-3 gx-xxl-4 flex-column flex-xl-row">
        <div class="c-video-info__img col-auto mb-3 mb-xl-0">
          <div class="c-circle">
            <img class="img-fluid" :src="thumb" alt="" />
          </div>
        </div>
        <div class="c-video-info__content col">
          <h3>{{ title }}</h3>
          <div class="c-text small">
            <p>{{ description }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
export default {
  setup(props) {
    const title = ref(props.title);
    const description = ref(props.description);
    const thumb = ref(props.thumb);

    return {
      title,
      description,
      thumb,
    };
  },
  props: ["title", "thumb", "description"],
  name: "Info",
};
</script>
